import * as React from "react";
import { useWallet } from "store/wallet";

export default function UtilityButtons() {
  const wallet = useWallet();
  const { isWeb3Enabled, address, web3 } = useWallet();

  const addBtntToken = async () => {
    if (isWeb3Enabled) {
      const tokenAddress = "0xcbd787129d6e36225d93e46fa08556cc7f3a2458";
      const tokenSymbol = "BTNTV2";
      const tokenDecimals = 18;
      const tokenImage =
        "https://assets.coingecko.com/coins/images/2875/small/Logobit.jpg";

      try {
        // wasAdded is a boolean. Like any RPC method, an error may be thrown.
        const wasAdded = await window["ethereum"].request({
          method: "wallet_watchAsset",
          params: {
            type: "ERC20", // Initially only supports ERC20, but eventually more!
            options: {
              address: tokenAddress, // The address that the token is at.
              symbol: tokenSymbol, // A ticker symbol or shorthand, up to 5 chars.
              decimals: tokenDecimals, // The number of decimals in the token
              image: tokenImage, // A string url of the token logo
            },
          },
        });

        if (wasAdded) {
          console.log("Thanks for your interest!");
        } else {
          console.log("Your loss!");
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  const addBscChain = async () => {
    try {
      // wasAdded is a boolean. Like any RPC method, an error may be thrown.
      const wasAdded = await window["ethereum"].request({
        method: "wallet_addEthereumChain",
        params: [
          {
            chainId: "0x38",
            chainName: "Binance Smart Chain",
            rpcUrls: ["https://bsc-dataseed.binance.org/"],
            nativeCurrency: {
              name: "BNB",
              symbol: "BNB",
              decimals: 18,
            },
            blockExplorerUrls: ["https://bscscan.com"],
          },
        ],
      });

      if (wasAdded) {
        console.log("Thanks for your interest for BSC Chain!");
      } else {
        console.log("Your loss!");
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="container">
      <div className="row">
        <div className="d-flex flex-row-reverse bd-highlight">
          <div className="p-2 bd-highlight">
            <button
              onClick={addBscChain}
              type="button"
              className="btn btn-outline-warning"
              style={{ color: "black" }}
            >
              <img
                src="https://bscscan.com/images/svg/brands/metamask.svg"
                width={25}
                alt=""
              />{" "}
              &nbsp; ADD BSC NETWORK
            </button>
          </div>
          <div className="p-2 bd-highlight">
            <button
              onClick={addBtntToken}
              type="button"
              className="btn btn-outline-primary"
            >
              <img
                src="https://assets.coingecko.com/coins/images/2875/small/Logobit.jpg"
                width={25}
                alt=""
              />{" "}
              &nbsp; ADD BTNT TOKEN
            </button>
          </div>
          <div className="p-2 bd-highlight">
            <a
              href="https://pancakeswap.finance/swap?outputCurrency=0xcbd787129d6e36225d93e46fa08556cc7f3a2458"
              target="_blank"
            >
              <button type="button" className="btn btn-outline-primary">
                🥞 Pancakeswap
              </button>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}
