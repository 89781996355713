import * as React from "react";
import Header from "../components/navbar/header";
import { ThemeProvider } from "@mui/material/styles";
import brandTheme from "../assets/theme";
import CustomFooter from "../components/navbar/footer";
import referAndEarnBtnt from "../assets/images/refer-and-earn-btnt.png";
import { useWallet } from "store/wallet";
import { BASE_URL } from "config/prod";
import { Button } from "@mui/material";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import Blockies from "react-blockies";
import bitnauticLogo from "../assets/images/bitnautic-app-logo.png";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { BTNT_REWARD_ADDRESS, BTNT_LP_V2_REWARD_ADDRESS } from "config/prod";
import BTNT_REWARD_ABI from "../assets/abi/poolBtntv2RewardAbi.json";
import BTNT_LP_REWARD_ABI from "../assets/abi/BtntLpV2Reward.json";
import ERC20TokenABI from "../assets/abi/ERC20.json";
import Swal from "sweetalert2";

import {
  uniqueNamesGenerator,
  adjectives,
  names,
  starWars,
} from "unique-names-generator";
import {
  EmailIcon,
  FacebookIcon,
  FacebookShareCount,
  LinkedinIcon,
  RedditIcon,
  RedditShareCount,
  TelegramIcon,
  TwitterIcon,
  WhatsappIcon,
} from "react-share";

import {
  EmailShareButton,
  FacebookShareButton,
  LinkedinShareButton,
  RedditShareButton,
  TelegramShareButton,
  TwitterShareButton,
  WhatsappShareButton,
} from "react-share";
import { maxWidth } from "@mui/system";
import { BTNT_TOKEN_CONTRACT_ADDRESSS } from "config/prod";

function Referral() {
  const [referralLink, setReferralLink] = React.useState("");
  const { isWeb3Enabled, web3, address } = useWallet();
  const [referralAmountValue, setReferralAmount] = React.useState(0);
  const [LpreferralAmountValue, setLpReferralAmount] = React.useState(0);
  const [getReferralCount, setReferralCount] = React.useState(0);
  const [getReferralClaimedAmount, setReferralClaimedAmount] =
    React.useState(0);
  const [btntCurrencyBal, setBtntCurrencyBal] = React.useState(0);

  const title = "BTNT Staking Reward Platform";

  const randomName = uniqueNamesGenerator({
    dictionaries: [adjectives, names, starWars],
  }); // big_red_donkey

  const claimLpBtntAmount = async () => {
    if (referralAmountValue > 0) {
      Swal.fire({
        title: "Transaction In Process... Do not close this window.",
        didOpen: () => {
          Swal.showLoading();
        },
      });
      const BtntRewardContract = new web3.eth.Contract(
        BTNT_REWARD_ABI,
        BTNT_REWARD_ADDRESS
      );
      var transaction = await BtntRewardContract.methods.claimReward().send({
        from: address,
      });
      if (transaction.transactionHash) {
        Swal.hideLoading();
        Swal.fire({
          title: "Transaction Successful",
          icon: "success",
          confirmButtonText: "Okay",
        });
        updateReferralvalue();
        return;
      }
    } else {
      Swal.fire({
        title: `Insufficient Referral Amount`,
        icon: "error",
        confirmButtonText: "Okay",
      });
    }
  };

  const shortName = uniqueNamesGenerator({
    dictionaries: [names, starWars], // colors can be omitted here as not used
    separator: " ",
    length: 2,
  });

  const updateReferralvalue = async () => {
    const BtntRewardContract = new web3.eth.Contract(
      BTNT_REWARD_ABI,
      BTNT_REWARD_ADDRESS
    );
    const BtntContract = new web3.eth.Contract(
      ERC20TokenABI,
      BTNT_TOKEN_CONTRACT_ADDRESSS
    );

    console.log(address, "address");
    const referralAmount = await BtntRewardContract.methods
      .referralReward(address)
      .call();

    const purchaseAmount = web3.utils.fromWei(
      referralAmount.toString(),
      "ether"
    );

    const BtntCurrBalVal = await BtntContract.methods.balanceOf(address).call();
    const btntCurrBalValinEth = web3.utils.fromWei(
      BtntCurrBalVal.toString(),
      "ether"
    );
    const referralCount = await BtntRewardContract.methods
      .getRefferalCount(address)
      .call();
    const referralClaimedAmount = await BtntRewardContract.methods
      .refAmount(address)
      .call();
    const referralClaimedAmountinEth = web3.utils.fromWei(
      referralClaimedAmount.toString(),
      "ether"
    );

    setReferralAmount(parseFloat(purchaseAmount).toFixed(4));
    setReferralClaimedAmount(parseFloat(referralClaimedAmountinEth).toFixed(4));
    setReferralCount(referralCount);
    setBtntCurrencyBal(parseFloat(btntCurrBalValinEth).toFixed(2));
  };

  React.useEffect(() => {
    if (isWeb3Enabled && address) {
      updateReferralvalue();
      setReferralLink(BASE_URL + "/stake-btnt/" + address);
    } else {
      setReferralLink("Your wallet is Not connected");
    }
  }, [address, isWeb3Enabled]);

  return (
    <>
      <ThemeProvider theme={brandTheme}>
        <Header />

        <div className="container-md mt-4">
          <div className="row">
            <div className="card rounded-5">
              <div className="card-body">
                <div className="row">
                  <div className="col-md-7 p-4">
                    <h2 className="card-title fw-bolder mt-10">
                      Refer Friends, and Earn BTNT
                    </h2>
                    <p className="card-text">
                      Invite your friends and connect, to stake in BTNT using
                      your link and you'll get 1.50% for Direct refererral of
                      their Staking - awarded in BTNT - for any pool they staked
                      BTNT.
                    </p>
                    {/* <input
                      disabled
                      type="text"
                      className="form-control rounded-5"
                      value={referralLink}
                      width="50px"
                    />
                    <Button variant="contained" endIcon={<ContentCopyIcon />}>
                      Copy
                    </Button> */}
                    <div className="input-group mb-3">
                      <input
                        disabled
                        type="text"
                        className=" form-control rounded-5 text-muted"
                        value={referralLink}
                        width="50px"
                      />
                      &nbsp;
                      <Button
                        disabled={!isWeb3Enabled}
                        variant="contained"
                        className="rounded-5 ml-5"
                        endIcon={<ContentCopyIcon />}
                        onClick={() => {
                          navigator.clipboard.writeText(referralLink);
                        }}
                      >
                        Copy
                      </Button>
                      {/* </input> */}
                    </div>
                    <p className="fs-8 text-muted text-center">
                      *Your referred user needs to invest with your referral
                      address link otherwise you'll lose referral Bonus.
                    </p>

                    <div className="Demo__container text-center pt-3">
                      <div className="Demo__some-network">
                        <FacebookShareButton
                          url={referralLink}
                          quote={title}
                          className="Demo__some-network__share-button"
                        >
                          <FacebookIcon size={32} round />
                        </FacebookShareButton>

                        <div>
                          <FacebookShareCount
                            url={referralLink}
                            className="Demo__some-network__share-count"
                          >
                            {(count) => count}
                          </FacebookShareCount>
                        </div>
                      </div>

                      <div className="Demo__some-network">
                        <TwitterShareButton
                          url={referralLink}
                          title={title}
                          className="Demo__some-network__share-button"
                        >
                          <TwitterIcon size={32} round />
                        </TwitterShareButton>

                        <div className="Demo__some-network__share-count">
                          &nbsp;
                        </div>
                      </div>
                      <div className="Demo__some-network">
                        <TelegramShareButton
                          url={referralLink}
                          title={title}
                          className="Demo__some-network__share-button"
                        >
                          <TelegramIcon size={32} round />
                        </TelegramShareButton>

                        <div className="Demo__some-network__share-count">
                          &nbsp;
                        </div>
                      </div>

                      <div className="Demo__some-network">
                        <WhatsappShareButton
                          url={referralLink}
                          title={title}
                          separator=":: "
                          className="Demo__some-network__share-button"
                        >
                          <WhatsappIcon size={32} round />
                        </WhatsappShareButton>

                        <div className="Demo__some-network__share-count">
                          &nbsp;
                        </div>
                      </div>

                      <div className="Demo__some-network">
                        <LinkedinShareButton
                          url={referralLink}
                          className="Demo__some-network__share-button"
                        >
                          <LinkedinIcon size={32} round />
                        </LinkedinShareButton>
                      </div>
                      <div className="Demo__some-network">
                        <RedditShareButton
                          url={referralLink}
                          title={title}
                          windowWidth={660}
                          windowHeight={460}
                          className="Demo__some-network__share-button"
                        >
                          <RedditIcon size={32} round />
                        </RedditShareButton>

                        <div>
                          <RedditShareCount
                            url={referralLink}
                            className="Demo__some-network__share-count"
                          />
                        </div>
                      </div>
                      <div className="Demo__some-network">
                        <EmailShareButton
                          url={referralLink}
                          subject={title}
                          body="body"
                          className="Demo__some-network__share-button"
                        >
                          <EmailIcon size={32} round />
                        </EmailShareButton>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-5 text-center">
                    <img
                      src={referAndEarnBtnt}
                      className="img-fluid"
                      alt="refer-and-earn-btnt"
                      width="90%"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="container-md mt-4 mb-10">
          <div className="row">
            <div className="col-md-4 col-sm-12">
              <div className="card">
                <div className="card-img-top">
                  <Blockies
                    seed={address}
                    scale={100}
                    className="img-fluid canvasProfile"
                  />
                  <div className="user text-center">
                    <div className="profile">
                      <img
                        src="https://cdn-icons-png.flaticon.com/512/2144/2144140.png"
                        className="rounded-circle"
                        width="80"
                        alt=""
                      />
                    </div>
                  </div>
                </div>
                <div className="card-body text-center">
                  <div className="mt-4 text-center">
                    <h4
                      className="mb-0"
                      data-toggle="tooltip"
                      data-placement="top"
                      title="Your Alise name"
                    >
                      {shortName}
                    </h4>
                    <span className="text-muted d-block mb-2">
                      {isWeb3Enabled && address !== undefined
                        ? address.substring(0, 5) +
                          "..." +
                          address.substring(address.length - 5)
                        : "  Connect Wallet"}
                    </span>

                    <Button
                      disabled={!isWeb3Enabled}
                      variant="contained"
                      className="rounded-5 ml-5"
                      endIcon={<ContentCopyIcon />}
                      onClick={() => {
                        navigator.clipboard.writeText(address);
                      }}
                    >
                      Copy Address
                    </Button>

                    <div className="d-flex justify-content-between align-items-center mt-4 px-4">
                      <div className="stats">
                        <h6 className="mb-0 fw-bold">BTNT Balance</h6>
                        <span>{btntCurrencyBal}</span>
                      </div>

                      <div className="stats">
                        <h6 className="mb-0 fw-bold">User Referred</h6>
                        <span
                          data-toggle="tooltip"
                          data-placement="top"
                          title="It may be a one person but invested multiple times"
                        >
                          {getReferralCount} <InfoOutlinedIcon />
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-8 col-sm-12">
              <div className="card rounded-3">
                <div className="card-body d-flex">
                  {/* <div className="col-5 m-5">
                    <p className="mb-3 fs-6 text-dark font-monospace">
                      Your shared referral Stats
                    </p>
                    <p className="mb-3 fs-6 text-dark font-bold">
                      Direct Pool Referral Reward
                    </p>

                    <div className="d-flex flex-row align-middle pt-3 pb-2">
                      <img
                        src={bitnauticLogo}
                        alt=""
                        width="60px"
                        height={60}
                      />
                      &nbsp;
                      <h2 className="display-4 fw-bolder">
                        {" "}
                        {referralAmountValue}{" "}
                      </h2>
                    </div>
                    <h5 className="m-3 text-dark d-flex">
                      BTNT Earned <InfoOutlinedIcon />
                    </h5>
                    <Button
                      disabled={!isWeb3Enabled}
                      variant="contained"
                      className="rounded-5 ml-5"
                      onClick={() => {
                        claimBtntAmount();
                      }}
                    >
                      Claim Referral
                    </Button>
                  </div> */}
                  <div className="col-5 m-5">
                    <p className="mb-3 fs-6 text-dark font-monospace">
                      Your shared referral Stats
                    </p>
                    <p className="mb-3 fs-6 text-dark font-bold">
                      Pool Referral Reward
                    </p>

                    <div className="d-flex flex-row align-middle pt-3 pb-2">
                      <img
                        src={bitnauticLogo}
                        alt=""
                        width="60px"
                        height={60}
                      />
                      &nbsp;
                      <h2 className="display-4 fw-bolder">
                        {" "}
                        {referralAmountValue}{" "}
                      </h2>
                    </div>
                    <h5 className="m-3 text-dark d-flex">
                      BTNT Earned <InfoOutlinedIcon />
                    </h5>
                    <Button
                      disabled={!isWeb3Enabled}
                      variant="contained"
                      className="rounded-5 ml-5"
                      onClick={() => {
                        claimLpBtntAmount();
                      }}
                    >
                      Claim Referral
                    </Button>
                  </div>
                  {/* <div className="col-3 m-5">
                    &nbsp;
                    <p className="mb-3 fs-6 text-dark font-bold">
                      LP Pool Referral Reward{" "}
                    </p>
                    <div className="d-flex flex-row align-middle pt-3 pb-2">
                      <img
                        src={bitnauticLogo}
                        alt=""
                        width="60px"
                        height={60}
                      />
                      &nbsp;
                      <h2 className="display-4 fw-bolder">
                        {" "}
                        {LpreferralAmountValue}{" "}
                      </h2>
                    </div>
                    <h5 className="m-3 text-dark d-flex">
                      BTNT Earned <InfoOutlinedIcon />
                    </h5>
                    <Button
                      disabled={!isWeb3Enabled}
                      variant="contained"
                      className="rounded-5 ml-5"
                      onClick={() => {
                        claimLpBtntAmount();
                      }}
                    >
                      Claim Referral
                    </Button>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>

        <CustomFooter />
      </ThemeProvider>
    </>
  );
}

export default Referral;
