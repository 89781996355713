import * as React from "react";
import Header from "../components/navbar/header";
import { ThemeProvider } from "@mui/material/styles";
import brandTheme from "../assets/theme";
import CustomFooter from "../components/navbar/footer";
import { Slider } from "../components/stakingSlider/slider";
import Pools from "../components/pools/pool";
import PoolsItem from "../assets/jsons/pools-testnet.json";
import UtilityButtons from "components/utilityButtons/utilityButtons";
import LpPools from "components/pools/lpPool";
import PoolBtntv2 from "components/pools/btntv2/poolBtntv2";
import LpPoolsItem from "../assets/jsons/poolBtntv2-mainet.json";

function StakingBtntv2() {
  return (
    <>
      <ThemeProvider theme={brandTheme}>
        <Header />
        <Slider />
        <UtilityButtons />
        {/* <Pools index={0} />

        {PoolsItem.map((i, index) => {
          return (
            <LpPools
              index={i.index}
              name={i.pair}
              title={i.title}
              rewardAddress={i.rewardAddress}
              staked={i.staked}
              imageURI={i.imageURI}
              priceTag={i.priceTag}
              quoteAddress={i.quoteAddress}
              quoteCurrency={i.quoteCurrency}
            />
          );
        })} */}

        {LpPoolsItem.map((i, index) => {
          return (
            <PoolBtntv2
              index={i.index}
              name={i.pair}
              title={i.title}
              rewardAddress={i.rewardAddress}
              staked={i.staked}
              imageURI={i.imageURI}
              priceTag={i.priceTag}
              quoteAddress={i.quoteAddress}
              quoteCurrency={i.quoteCurrency}
              buyTokenContract={i.buyTokenContract}
            />
          );
        })}

        {/* <Pools /> */}
        <CustomFooter />
      </ThemeProvider>
    </>
  );
}

export default StakingBtntv2;
