import * as React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Box,
  Button,
  Container,
  Grid,
  MenuItem,
  Paper,
  Select,
  TextField,
} from "@mui/material";
import { useWallet } from "store/wallet";
// import { rewardAddress } from "config/prod";
import BTNT_REWARD_ABI from "../../../assets/abi/poolBtntv2RewardAbi.json";
import ERC20TokenABI from "../../../assets/abi/ERC20.json";
import PancakeRouterABI from "../../../assets/abi/PancakeRouterABI.json";
import BuyTokenABI from "../../../assets/abi/buyTokenAbi.json";
import { useParams } from "react-router-dom";
import BigNumber from "bignumber.js";
import { BTNT_TOKEN_CONTRACT_ADDRESSS } from "config/prod";
import { DEFAULT_REFERRAL_ADDRESS } from "config/prod";
import {
  PANCAKESWAP_ROUTER_ADDRESS,
  SPECIAL_WHITELIST_ADDRESS,
} from "config/prod";
import InputAdornment from "@mui/material/InputAdornment";

const Swal = require("sweetalert2");

export default function PoolBtntv2({
  index,
  rewardAddress,
  title,
  staked,
  quoteAddress,
  quoteCurrency,
  imageURI,
  buyTokenContract,
}) {
  const [stakingAmount, setStakingAmount] = React.useState(0);
  const { isWeb3Enabled, web3, address } = useWallet();
  const [rewardAmount, setRewardAmount] = React.useState(0);
  const [stakedAmount, setStakedAmount] = React.useState(0);
  const [totalStakedAmount, setTotalStakedAmount] = React.useState(0);
  const [quoteCurrencyBal, setQuoteCurrencyBal] = React.useState(0.0);
  const [btntCurrencyBal, setBtntCurrencyBal] = React.useState(0);
  const [frenPrice, setFrenPrice] = React.useState(0);
  const [rewardFrenPrice, setRewardFrenPrice] = React.useState(0);
  const [userLockEndTimeVal, setUserLockEndTimeVal] = React.useState(0);
  const [allowanceValue, setAllowanceValue] = React.useState(0);
  const [isAllowance, setAllowance] = React.useState(false);
  const [pair, setPair] = React.useState(quoteCurrency);
  // const [imageURI, setImageURI] = React.useState("");
  const [quoteTokenAddress, setQuoteTokenAddress] =
    React.useState(quoteAddress);

  var { referralAddress } = useParams();

  const handleClickMaxValue = async () => {
    setStakingAmount(quoteCurrencyBal);
    if (parseFloat(allowanceValue) < parseFloat(quoteCurrencyBal)) {
      setAllowance(true);
    } else {
      setAllowance(false);
    }
  };

  const handleStakeAmount = async (event) => {
    setStakingAmount(event.target.value);
    if (
      parseFloat(allowanceValue) < parseFloat(event.target.value) &&
      pair !== "BNB"
    ) {
      setAllowance(true);
    } else {
      setAllowance(false);
    }
  };

  function getFlooredFixed(v, d) {
    return (Math.floor(v * Math.pow(10, d)) / Math.pow(10, d)).toFixed(d);
  }

  const handleStakeToken = async (index) => {
    if (
      localStorage.getItem("referralAddress") === undefined ||
      localStorage.getItem("referralAddress") === "" ||
      localStorage.getItem("referralAddress") === null
    ) {
      referralAddress = DEFAULT_REFERRAL_ADDRESS;
    } else {
      referralAddress = localStorage.getItem("referralAddress");
    }
    if (quoteCurrencyBal < stakingAmount) {
      Swal.fire({
        title: `Insufficient ${pair} Amount`,
        icon: "error",
        confirmButtonText: "Okay",
      });
      return;
    }
    if (
      stakingAmount == 0 ||
      stakingAmount === undefined ||
      stakingAmount === ""
    ) {
      Swal.fire({
        title: "Incorrect Amount!",
        text: "Enter the correct amount",
        icon: "error",
        confirmButtonText: "Okay",
      });
      return;
    }
    if (referralAddress === address) {
      Swal.fire({
        title: "Incorrect Referral Address!",
        text: "You have entered your own Address as a Referral!",
        icon: "error",
        confirmButtonText: "Okay",
      });
      return;
    }
    Swal.fire({
      title: "Transaction In Process... Do not close this window.",
      didOpen: () => {
        Swal.showLoading();
      },
    });
    if (isWeb3Enabled && pair !== "BNB") {
      const quoteContract = new web3.eth.Contract(
        ERC20TokenABI,
        quoteTokenAddress
      );
      const buyTokenContractObj = new web3.eth.Contract(
        BuyTokenABI,
        buyTokenContract
      );
      var finalStakeAmount = getFlooredFixed(stakingAmount, 8);
      var purchaseAmount = await web3.utils.toWei(
        finalStakeAmount.toString(),
        "ether"
      );
      const allowances = await quoteContract.methods
        .allowance(address, buyTokenContract)
        .call();
      if (parseFloat(allowances) < parseFloat(purchaseAmount)) {
        const approve = await quoteContract.methods
          .approve(buyTokenContract, "9".repeat(64))
          .send({
            from: address,
          });
        if (approve) {
          Swal.hideLoading();
          Swal.fire({
            title: "Transaction Successful",
            text: "You have Approval for the transaction. Next Click on the on the stake button.",
            icon: "success",
            confirmButtonText: "Okay",
          });
          setAllowance(false);
        }
        updatePoolValues();
        if (!approve.transactionHash) return "Transaction Failed";
      } else {
        var transaction = await buyTokenContractObj.methods
          .stakeBtntInPool(purchaseAmount.toString(), referralAddress)
          .send({
            from: address,
          });
        if (transaction) {
          Swal.hideLoading();
          Swal.fire({
            title: "Transaction Successful",
            text: "You have deposited your BTNT tokens and now is part of the farm.",
            icon: "success",
            confirmButtonText: "Okay",
            footer: `<a href="https://bscscan.com/tx/${transaction.transactionHash}">Transaction Details</a>`,
          });
        }
        updatePoolValues();
        return transaction;
      }
    } else if (isWeb3Enabled) {
      // const BtntRewardContract = new web3.eth.Contract(
      //   BTNT_REWARD_ABI,
      //   rewardAddress
      // );
      // const purchaseAmount = await web3.utils.toWei(
      //   stakingAmount.toString(),
      //   "ether"
      // );
      // var transactionBNb = await BtntRewardContract.methods
      //   .zapBtntForNative(
      //     quoteTokenAddress,
      //     BTNT_TOKEN_CONTRACT_ADDRESSS,
      //     index,
      //     purchaseAmount.toString(),
      //     referralAddress
      //   )
      //   .send({
      //     from: address,
      //     value: purchaseAmount,
      //     gasPrice: web3.utils.toHex(web3.utils.toWei("10", "gwei")),
      //   });
      // if (transactionBNb) {
      //   Swal.hideLoading();
      //   Swal.fire({
      //     title: "Transaction Successful",
      //     text: "You have deposited your BTNT tokens and now is part of the farm.",
      //     icon: "success",
      //     confirmButtonText: "Okay",
      //   });
      // }
      // updatePoolValues();
      // window.location.reload();
      // return transactionBNb;
    }
  };

  const handleSpecialStakeToken = async (index) => {
    if (
      localStorage.getItem("referralAddress") === undefined ||
      localStorage.getItem("referralAddress") === "" ||
      localStorage.getItem("referralAddress") === null
    ) {
      referralAddress = DEFAULT_REFERRAL_ADDRESS;
    } else {
      referralAddress = localStorage.getItem("referralAddress");
    }
    if (
      stakingAmount == 0 ||
      stakingAmount === undefined ||
      stakingAmount === ""
    ) {
      Swal.fire({
        title: "Incorrect Amount!",
        text: "Enter the correct amount",
        icon: "error",
        confirmButtonText: "Okay",
      });
      return;
    }
    if (referralAddress === address) {
      Swal.fire({
        title: "Incorrect Referral Address!",
        text: "You have entered your own Address as a Referral!",
        icon: "error",
        confirmButtonText: "Okay",
      });
      return;
    }
    Swal.fire({
      title: "Transaction In Process... Do not close this window.",
      didOpen: () => {
        Swal.showLoading();
      },
    });
    if (isWeb3Enabled && pair !== "BNB") {
      const quoteContract = new web3.eth.Contract(
        ERC20TokenABI,
        BTNT_TOKEN_CONTRACT_ADDRESSS
      );
      const buyTokenContractObj = new web3.eth.Contract(
        BTNT_REWARD_ABI,
        rewardAddress
      );
      var finalStakeAmount = getFlooredFixed(stakingAmount, 8);
      var purchaseAmount = await web3.utils.toWei(
        finalStakeAmount.toString(),
        "ether"
      );
      const allowances = await quoteContract.methods
        .allowance(address, rewardAddress)
        .call();

      if (parseFloat(allowances) < parseFloat(purchaseAmount)) {
        const approve = await quoteContract.methods
          .approve(rewardAddress, "9".repeat(64))
          .send({
            from: address,
          });
        if (approve) {
          Swal.hideLoading();
          Swal.fire({
            title: "Transaction Successful",
            text: "You have Approval for the transaction. Next Click on the on the stake button.",
            icon: "success",
            confirmButtonText: "Okay",
          });
          setAllowance(false);
        }
        updatePoolValues();
        if (!approve.transactionHash) return "Transaction Failed";
      } else {
        var transaction = await buyTokenContractObj.methods
          .depositExternalBTNT(referralAddress, purchaseAmount.toString())
          .send({
            from: address,
          });
        if (transaction) {
          Swal.hideLoading();
          Swal.fire({
            title: "Transaction Successful",
            text: "You have deposited your BTNT tokens and now is part of the farm.",
            icon: "success",
            confirmButtonText: "Okay",
            footer: `<a href="https://bscscan.com/tx/${transaction.transactionHash}">Transaction Details</a>`,
          });
        }
        updatePoolValues();
        return transaction;
      }
    } else if (isWeb3Enabled) {
    }
  };

  const updatePoolValues = React.useCallback(async () => {
    console.log("Welcome to BTNTv2 pools");
    const BtntContract = new web3.eth.Contract(
      ERC20TokenABI,
      BTNT_TOKEN_CONTRACT_ADDRESSS
    );
    const BtntRewardContract = new web3.eth.Contract(
      BTNT_REWARD_ABI,
      rewardAddress
    );
    const quoteContract = new web3.eth.Contract(
      ERC20TokenABI,
      quoteTokenAddress
    );
    const pendingAmount = await BtntRewardContract.methods
      .getGeneratedReward(address)
      .call();
    const purchaseAmount = web3.utils.fromWei(
      pendingAmount.toString(),
      "ether"
    );
    const userInfo = await BtntRewardContract.methods.userInfo(address).call();
    const stakedBTNT = web3.utils.fromWei(
      userInfo["btntAmount"].toString(),
      "ether"
    );
    const poolInfo = await BtntRewardContract.methods
      .globalStakedAmount()
      .call();
    const depositedBTNT = web3.utils.fromWei(poolInfo.toString(), "ether");
    var quoteCurrBalVal;
    if (pair === "BNB") {
      quoteCurrBalVal = await web3.eth.getBalance(address);
    } else {
      quoteCurrBalVal = await quoteContract.methods.balanceOf(address).call();
    }
    const quoteCurrBalValinEth = web3.utils.fromWei(
      quoteCurrBalVal.toString(),
      "ether"
    );
    const BtntCurrBalVal = await BtntContract.methods.balanceOf(address).call();
    const btntCurrBalValinEth = web3.utils.fromWei(
      BtntCurrBalVal.toString(),
      "ether"
    );
    const PancakeContract = new web3.eth.Contract(
      PancakeRouterABI,
      PANCAKESWAP_ROUTER_ADDRESS
    );

    const amountsVal = await PancakeContract.methods
      .getAmountsOut(new BigNumber(1e18).toString(), [
        BTNT_TOKEN_CONTRACT_ADDRESSS,
        quoteTokenAddress,
      ])
      .call();

    const usdtPrice = await (
      new BigNumber(amountsVal[1]).multipliedBy(1e-18).toNumber() *
      parseFloat(stakedBTNT)
    ).toFixed(4);

    const usdtRewardPrice = await (
      new BigNumber(amountsVal[1]).multipliedBy(1e-18).toNumber() *
      parseFloat(purchaseAmount)
    ).toFixed(8);

    setRewardFrenPrice(usdtRewardPrice);

    setFrenPrice(usdtPrice);

    const allowances = await quoteContract.methods
      .allowance(address, buyTokenContract)
      .call();
    const allowancesinEth = web3.utils.fromWei(allowances.toString(), "ether");

    setUserLockEndTimeVal(userInfo["lockEndTime"]);
    setAllowanceValue(parseFloat(allowancesinEth));
    setBtntCurrencyBal(parseFloat(btntCurrBalValinEth).toFixed(2));
    setQuoteCurrencyBal(parseFloat(quoteCurrBalValinEth));
    setTotalStakedAmount(parseFloat(depositedBTNT).toFixed(4));
    setRewardAmount(parseFloat(purchaseAmount).toFixed(8));
    setStakedAmount(parseFloat(stakedBTNT).toFixed(4));
  });

  const handleClaim = async (index) => {
    if (isWeb3Enabled && address) {
      if (rewardAmount !== 0) {
        const BtntRewardContract = new web3.eth.Contract(
          BTNT_REWARD_ABI,
          rewardAddress
        );
        var transaction = await BtntRewardContract.methods.claim().send({
          from: address,
        });
        if (transaction.transactionHash) {
          Swal.fire({
            title: "Transaction Successful",
            icon: "success",
            confirmButtonText: "Okay",
            footer: `<a href="https://bscscan.com/tx/${transaction.transactionHash}">Transaction Details</a>`,
          });
          updatePoolValues();
          return;
        }
      } else {
        Swal.fire({
          title: "No Reward.",
          text: "You don't have sufficient reward amount to claim.",
          icon: "error",
          confirmButtonText: "Okay",
        });
        updatePoolValues();
        return;
      }
    }
  };

  const handleWithdraw = async (index) => {
    if (isWeb3Enabled && address) {
      if (
        rewardAmount !== 0 &&
        Math.floor(Date.now() / 1000) >= parseInt(userLockEndTimeVal) + 1209600
      ) {
        const BtntRewardContract = new web3.eth.Contract(
          BTNT_REWARD_ABI,
          rewardAddress
        );
        var transaction = await BtntRewardContract.methods.withdraw().send({
          from: address,
        });
        if (transaction.transactionHash) {
          Swal.fire({
            title: "Transaction Successful",
            icon: "success",
            confirmButtonText: "Okay",
            footer: `<a href="https://bscscan.com/tx/${transaction.transactionHash}">Transaction Details</a>`,
          });
          window.location.reload();
          updatePoolValues();
          return;
        }
      } else {
        Swal.fire({
          title: "Insuffient or Locked for 2 Weeks",
          text: "You don't have staked any amount or it's locked. But you can claim your reward amount.",
          icon: "error",
          confirmButtonText: "Okay",
        });
        return;
      }
    }
  };

  React.useEffect(() => {
    if (isWeb3Enabled && address) {
      if (referralAddress !== undefined) {
        localStorage.setItem("referralAddress", referralAddress);
      }
      setQuoteTokenAddress(quoteAddress);
      setPair(quoteCurrency);
    }
  }, [
    address,
    isWeb3Enabled,
    pair,
    quoteAddress,
    quoteCurrency,
    referralAddress,
  ]);

  React.useEffect(() => {
    if (isWeb3Enabled && address) {
      updatePoolValues();
    }
  }, [
    isWeb3Enabled,
    address,
    quoteTokenAddress,
    updatePoolValues,
    quoteCurrency,
    pair,
  ]);
  return (
    <div>
      <Box>
        <Container>
          <Accordion sx={{ mb: 3 }} className="rounded-3">
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <div className="container text-center">
                {/* <Stack direction="row" spacing={10}> */}
                <div className="row">
                  <div className="col-lg-2 col-12">
                    <img src={imageURI} alt="" />
                  </div>
                  <div className="col-lg-3 col-6">
                    <Typography className="fw-bolder fs-4">{title}</Typography>
                    <Typography sx={{ color: "#000" }} className="fs-6">
                      Pay in {quoteCurrency}, and earn BTNT
                    </Typography>
                  </div>
                  <div className="col-lg-2 col-6">
                    <Typography
                      className="fw-bold text-muted"
                      varient="h4"
                      component="h4"
                    >
                      STAKED
                    </Typography>
                    <Typography sx={{ color: "#000" }}>
                      {stakedAmount} {staked}
                    </Typography>
                    {/* <Typography sx={{ color: "#7986cb" }}>
                      {frenPrice} {pair}
                    </Typography> */}
                  </div>
                  <div className="col-lg-3 col-12">
                    <Typography
                      className="fw-bold text-muted"
                      varient="h4"
                      component="h4"
                    >
                      TOTAL STAKED
                    </Typography>
                    <Typography sx={{ color: "#000" }}>
                      {totalStakedAmount} {staked}
                    </Typography>
                  </div>
                  <div className="col-lg-2 col-12">
                    <Typography
                      className="fw-bold text-muted"
                      varient="h4"
                      component="h4"
                    >
                      POOL APR
                    </Typography>
                    <Typography sx={{ color: "#000" }}>NA</Typography>
                  </div>
                </div>
                {/* </Stack> */}
              </div>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container spacing={2}>
                <Grid item xs={12} md={4}>
                  <Paper
                    variant="outlined"
                    className="container align-middle mt-2 p-3 lh-lg border border-secondary rounded-4"
                  >
                    {/* <Typography className="fw-normal">
                      {" "}
                      Total locked:{" "}
                      <span className="text-end">42000 BTNT </span>
                    </Typography> */}
                    <div className="container">
                      <div className="row">
                        <div className="col">Total locked:</div>
                        <div className="col align-self-end text-end text-dark">
                          {4240 + totalStakedAmount}BTNT
                        </div>
                      </div>
                      <div className="row">
                        <div className="col">Avg. lock duration:</div>
                        <div className="col text-end">2 Weeks</div>
                      </div>
                      <div className="row">
                        <div className="col">{pair} Balance:</div>
                        <div className="col text-end ext-end text-dark">
                          {" "}
                          {quoteCurrencyBal.toFixed(2)} {pair}
                        </div>
                      </div>
                      <div className="row">
                        <div className="col">BTNT Balance:</div>
                        <div className="col text-end ext-end text-dark">
                          {" "}
                          {btntCurrencyBal} BTNT
                        </div>
                      </div>
                    </div>
                  </Paper>
                </Grid>
                <Grid item xs={12} md={3}>
                  <Paper
                    variant="outlined"
                    className="container align-middle mt-2 p-2 lh-lg border border-secondary rounded-4 text-center"
                  >
                    <p className="fw-bold text-dark">
                      Recent <span className="text-primary"> BTNT </span> Earned{" "}
                    </p>
                    <p className="fs-5 fw-bolder">{rewardAmount} BTNT</p>
                    <Typography sx={{ color: "#7986cb" }}>
                      {rewardFrenPrice} {pair}
                    </Typography>
                    <Button
                      variant="contained"
                      onClick={() => handleClaim(index)}
                    >
                      Claim
                    </Button>
                    &nbsp; &nbsp;
                    <Button
                      variant="contained"
                      onClick={() => handleWithdraw(index)}
                      className="mr-2"
                    >
                      Unstake
                    </Button>
                  </Paper>
                </Grid>
                <Grid item xs={12} md={5}>
                  <Paper
                    variant="outlined"
                    className="container align-middle mt-2 p-2 lh-lg border border-secondary rounded-4 text-center"
                  >
                    <p className="fw-bold text-dark">
                      PAY <span className="text-success"> {pair} </span> STAKE{" "}
                      <span className="text-primary"> BTNT </span>
                    </p>
                    <TextField
                      id="filled-basic"
                      label="Enter Amount"
                      variant="filled"
                      className="pb-2"
                      type="number"
                      onChange={handleStakeAmount}
                      value={stakingAmount}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment
                            position="end"
                            onClick={handleClickMaxValue}
                          >
                            MAX
                          </InputAdornment>
                        ),
                      }}
                    />
                    <Select
                      // onChange={(e) => setPair(e.target.value)}
                      // value={pair}
                      displayEmpty
                      inputProps={{ "aria-label": "Without label" }}
                    >
                      <MenuItem disabled value="">
                        <em>Select Token</em>
                      </MenuItem>
                      <MenuItem>{quoteCurrency}</MenuItem>
                      {/* <MenuItem value={"BNB"}>BNB</MenuItem>
                      <MenuItem value={"BUSD"}>BUSD</MenuItem> */}
                    </Select>
                    <br />
                    &nbsp;
                    {address === SPECIAL_WHITELIST_ADDRESS ? (
                      <>
                        <Button
                          variant="contained"
                          onClick={() => handleSpecialStakeToken(index)}
                          style={{
                            display: isAllowance ? "inline-flex" : "none",
                          }}
                        >
                          {isWeb3Enabled ? "Approval" : "Connect Wallet"}
                        </Button>
                        &nbsp;
                        <Button
                          variant="contained"
                          onClick={() => handleSpecialStakeToken(index)}
                          disabled={isAllowance}
                        >
                          {isWeb3Enabled ? "Special Stake" : "Connect Wallet"}
                        </Button>
                      </>
                    ) : (
                      <>
                        <Button
                          variant="contained"
                          onClick={() => handleStakeToken(index)}
                          style={{
                            display: isAllowance ? "inline-flex" : "none",
                          }}
                        >
                          {isWeb3Enabled ? "Approval" : "Connect Wallet"}
                        </Button>
                        &nbsp;
                        <Button
                          variant="contained"
                          onClick={() => handleStakeToken(index)}
                          disabled={isAllowance}
                          style={{ mb: "10px" }}
                        >
                          {isWeb3Enabled ? "Stake" : "Connect Wallet"}
                        </Button>
                      </>
                    )}
                  </Paper>
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>
        </Container>
      </Box>
    </div>
  );
}
