// Mainnet Details
module.exports = {
  RPC_URL: "",
  BASE_URL: "https://stakingv2.bitnautic.org",
  // BASE_URL: "http://localhost:3000",

  BTNT_REWARD_ADDRESS: "0x30808d5808B684F89bC6E2148afb08C4C47f7a4d",

  BTNT_LP_REWARD_ADDRESS: "0xeAA65F04793Db7b9C37570413b3e91e2463c5f9B",
  BNB_CONTRACT_ADDRESS: "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
  BUSD_CONTRACT_ADDRESS: "0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56",
  USDT_CONTRACT_ADDRESS: "0x55d398326f99059fF775485246999027B3197955",

  BTNT_TOKEN_CONTRACT_ADDRESSS: "0xCbd787129d6e36225D93E46fA08556cC7F3a2458",

  DEFAULT_REFERRAL_ADDRESS: "0xB00e51A4c1caee5749AaBd76cdCD3B07B77A2223",
  PANCAKESWAP_ROUTER_ADDRESS: "0x10ED43C718714eb63d5aA57B78B54704E256024E",
  BTNT_LP_V2_REWARD_ADDRESS: "0x88252CAd7ED245B99BE005dbaD80Ef6B9abac3Bb",

  SPECIAL_WHITELIST_ADDRESS: "0xB00e51A4c1caee5749AaBd76cdCD3B07B77A2223",
};

// Testnet Details
// module.exports = {
//   RPC_URL: "",
//   BASE_URL: "https://stakingv2.bitnautic.org",
//   // BASE_URL: "http://localhost:3000",
//   BTNT_REWARD_ADDRESS: "0x0ec3B6252C62fAcF339F52D4158ca6C19774BBc7",

//   BNB_CONTRACT_ADDRESS: "0xeA63FDa80983d58533e818034A7a18c486CA1310",
//   BUSD_CONTRACT_ADDRESS: "0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56",
//   USDT_CONTRACT_ADDRESS: "0xd09b8734dBe94C0FDd4B12E565E274A44a805D0b",

//   BTNT_TOKEN_CONTRACT_ADDRESSS: "0x7E6942fAE32F0aD9B3249824530F1939a3eF5A83",
//   DEFAULT_REFERRAL_ADDRESS: "0x21F2880da6b96C07dF99CbA694b2eC01a1cA29a6",
//   PANCAKESWAP_ROUTER_ADDRESS: "0xD99D1c33F9fC3444f8101754aBC46c52416550D1",

//   BTNT_LP_REWARD_ADDRESS: "0x02A6690b550b65C71f522383B8260b72AA9764BB",
//   BTNT_LP_V2_REWARD_ADDRESS: "0x5A6380C43be307D220D273053D9323232D353bAD",

//   SPECIAL_WHITELIST_ADDRESS: "0xeFbdCDa0486c3fe34ABc79DAEAD9d22ef98f0fC1",
// };
